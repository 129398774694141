<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหา
          </h2>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">ประเภทที่จะค้นหา</label>
              <b-form-select
                v-model="search_type"
                :options="search_type_option"
                placeholder="เลือกประเภทที่จะค้นหา"
              />
            </div>
          </div>
          <div

            class="col-md-3"
          >
            <div
              class="form-group"
            >
              <label for="username">Keyword</label>
              <input
                id="username"
                v-model="search_val"
                :disabled="!search_type || !(search_type == 1 || search_type == 2 || search_type == 3)"
                type="text"
                class="form-control"
                placeholder="กรอกข้อมูลที่ต้องการค้นหา"
              >
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateStart">วันที่เริ่มต้น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateEnd">วันที่สิ้นสุด</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
              />
            </div>
          </div>
          <div class="col-md-3">
            <button
              class="btn btn-gradient-dark"
              @click="getHistory()"
            >
              <feather-icon icon="SearchIcon" />
              ค้นหา
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div
            style="display: flex"
          >
            <b-button
              v-b-modal.modal-1
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="gradient-primary"
              class="mr-1"
              @click="search = false, tel = null , member = null"
            >
              ทำรายการโยกเงิน
            </b-button>
          </div>
        </div>
      </b-card-body>
      <b-table
        small
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>

        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(picture)="data">
          <b-avatar
            size="lg"
            :src="data.item.picture"
          />
        </template>
        <template #cell(before_balance)="data">
          <p class="font-weight-bolder text-warning mb-0">
            {{ data.item.before_balance ? Commas(data.item.before_balance.toFixed(2)) : 0.00 }}
          </p>
        </template>
        <template #cell(balance)="data">
          <p class="font-weight-bolder text-success mb-0">
            {{ data.item.balance ? Commas(data.item.balance.toFixed(2)) : 0.00 }}
          </p>
        </template>
        <template #cell(amount)="data">
          <p class="font-weight-bolder text-danger mb-0">
            {{ data.item.amount ? Commas(data.item.amount.toFixed(2)) : 0.00 }}
          </p>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge
              v-if="data.item.status === 'waiting'"
              variant="light-primary"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'processing'"
              variant="light-warning"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'success'"
              variant="light-success"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'cancel'"
              variant="light-warning"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'waiting_approve'"
              variant="light-secondary"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'error'"
              variant="light-danger"
            >
              {{ data.item.status }}
            </b-badge>

          </div>
        </template>
        <template #cell(frome)="data">
          <div>
            <div class="text-center">
              <img
                v-if="data.item.frome === '004'"
                :src="`/bankIcon/KBANK.png`"
                alt="bank"
                class="bank-icon"
                :style="`background:#009345;`"
              >
              <img
                v-else-if="data.item.frome === '014'"
                :src="`/bankIcon/scb.png`"
                alt="bank"
                class="bank-icon"
                :style="`background:#4c2786;`"
              >
              <br>
              <small class="text-info"><u>{{ data.item.frome_acc }}</u></small>
            </div>
          </div>
        </template>
        <template #cell(bank)="data">
          <div>
            <div class="text-center">
              <img
                :src="`/bankIcon/${data.item.bank_path_photo ? data.item.bank_path_photo : 'dollar.png'}`"
                alt="bank"
                class="bank-icon"
                :style="`background: ${data.item.bank_bg ? data.item.bank_bg : '#fff'};`"
              ><br>
              <small class="text-info"><u>{{ data.item.acc_no }}</u></small>
            </div>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- <b-button
              v-if="data.item.status === 'waiting_approve' || UserData.role === 'admin' || UserData.role === 'agadmin'"
              v-b-tooltip.hover.v-success
              title="อนุมัติ"
              variant="gradient-success"
              size="sm"
              @click="Confirm(data.item,'approve')"
            >
              อนุมัติ
            </b-button> -->
            <b-button
              v-if="data.item.status === 'waiting_approve' && UserData.role === 'agadmin'"
              v-b-tooltip.hover.v-success
              title="อนุมัติ"
              variant="gradient-success"
              size="sm"
              @click="Confirm(data.item,'approve')"
            >
              อนุมัติ
            </b-button>
            &nbsp;&nbsp;&nbsp;
            <b-button
              v-if="data.item.status === 'waiting_approve' && UserData.role === 'agadmin'"
              v-b-tooltip.hover.v-danger
              variant="gradient-danger"
              title="คืนเงิน"
              size="sm"
              @click="Confirm(data.item,'cancel')"
            >
              ยกเลิก
            </b-button>
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getHistory()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getHistory()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-modal
        id="modal-1"
        title="โยกเงิน"
        ok-title="ยืนยัน"
        @ok="ConfirmSubmit()"
      >
        <!-- <div class="mb-2">
          <label for="favorite">รายการโปรด</label>

          <div class="d-flex">
            <div
              v-for="item in favorite"
              :key="item.id"
              class="text-center"
            >
              <div class="favrt">
                <img
                  src="/bankIcon/KBANK.png"
                  alt="bank-form"
                  class="img-fluid"
                >
              </div>
              <p class="mb-0">
                {{ item.name }}
              </p>
            </div>

            <div class="text-center">
              <div
                v-b-modal.modal-add
                class="favrt-add"
              >
                <i class="fa fa-plus fa-2x" />
              </div>
            </div>
          </div>
        </div> -->
        <b-form-group>
          <label for="web">บัญชีที่ต้องการถอน</label>
          <b-form-select
            v-model="acc_data"
            :options="ListAcc"
            value-field="ID"
            text-field="displayname"
            placeholder="เลือกบัญชีที่ต้องการถอน"
          />
        </b-form-group>
        <b-form-group
          label="จำนวนเงินที่ถอน"
          label-for="vi-first-name"
        >
          <b-form-input
            id="vi-first-name"
            v-model="amount"
            placeholder="0"
          />
        </b-form-group>
        <b-form-group>
          <label for="web">ถอนไปยังธนาคาร</label>
          <b-form-select
            v-model="bank"
            :options="BankList"
            value-field="bankcode"
            text-field="name"
            placeholder="เลือกธนาคาร"
          />
        </b-form-group>
        <b-form-group
          label="ถอนไปยังเลขที่บัญชี (ไม่ต้องใส่ -)"
          label-for="vi-first-acc"
        >
          <b-form-input
            id="vi-first-acc"
            v-model="acc_no"
            placeholder=""
          />
        </b-form-group>
      </b-modal>

      <b-modal
        id="modal-add"
        title="เพิ่มรายการโปรด"
        ok-title="ยืนยัน"
        cancel-title="ยกเลิก"
        @ok="addFavorite"
      >
        <b-form-group
          label="ชื่อรายการ"
          label-for="vi-first-acc"
        >
          <b-form-input
            id="vi-first-acc"
            v-model="favoriteName"
            placeholder=""
          />
        </b-form-group>

        <b-form-group>
          <label for="web">บัญชีที่ต้องการถอน</label>
          <b-form-select
            v-model="acc_data"
            :options="ListAcc"
            value-field="ID"
            text-field="displayname"
            placeholder="เลือกบัญชีที่ต้องการถอน"
          />
        </b-form-group>

        <b-form-group>
          <label for="web">ถอนไปยังธนาคาร</label>
          <b-form-select
            v-model="bank"
            :options="BankList"
            value-field="bankcode"
            text-field="name"
            placeholder="เลือกธนาคาร"
          />
        </b-form-group>
        <b-form-group
          label="ถอนไปยังเลขที่บัญชี (ไม่ต้องใส่ -)"
          label-for="vi-first-acc"
        >
          <b-form-input
            id="vi-first-acc"
            v-model="acc_no"
            placeholder=""
          />
        </b-form-group>

        <hr>

        <div>
          <label for="favorite">เลือกแก้ไขรายการ</label>

          <div class="d-flex">
            <div
              v-for="item in favorite"
              :key="item.id"
              class="text-center txtn"
              @click="Selected = item"
            >
              <div class="favrt">
                <img
                  src="/bankIcon/KBANK.png"
                  alt="bank-form"
                  class="img-fluid"
                >
              </div>
              <p class="mb-0">
                {{ item.name }}
              </p>

              <div
                class="closed-x"
                @click="deleteFav()"
              >
                <i class="fa fa-times" />
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
import moment from 'moment-timezone'
// import flatPickr from 'vue-flatpickr-component'
import {
  // BRow,
  // BCol,
  VBTooltip, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BFormInput, BButton, BCardBody, VBToggle, BOverlay, BIconController, BBadge,
  // BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    // BRow,
    // BCol,
    // vSelect,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BBadge,
    // BFormRadio,
    // flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      favorite: [
        { id: 1, name: 'test1' },
        { id: 2, name: 'test2' },
        { id: 3, name: 'test3' },
        { id: 4, name: 'test4' },
        { id: 5, name: 'test5' },
      ],
      search_type_option: [
        { value: 1, text: 'จากธนาคาร' },
        { value: 2, text: 'ไปยังธนาคาร' },
        { value: 3, text: 'จำนวนเงิน' },
        { value: 4, text: 'สถานะสำเร็จ' },
        { value: 5, text: 'สถานะยกเลิก' },
        { value: 6, text: 'สถานะไม่สำเร็จ' },
      ],
      search_type: null,
      search_val: null,
      show: false,
      show2: false,
      Selected: null,
      depositdata: [],
      member: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'frome', label: 'จากธนาคาร' },
        { key: 'bank', label: 'ไปยังธนาคาร' },
        { key: 'before_balance', label: 'จำนวนเงินก่อนถอน' },
        { key: 'amount', label: 'เครดิตที่ถอน' },
        { key: 'after_balance', label: 'ยอดเครดิตหลังถอน' },
        { key: 'addbyName', label: 'แจ้งถอนโดย' },
        { key: 'approveby', label: 'อนุมัติโดย' },
        { key: 'approve_time', label: 'อนุมัติเมื่อ' },
        { key: 'status', label: 'status' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'actions', label: 'อนุมัติ/ยกเลิก' },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      tel: null,
      search: false,
      dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      ListAcc: [],
      BankList: [],
      acc_data: null,
      acc_no: null,
      bank: null,
      amount: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  // created() {
  //   this.interval = setInterval(() => {
  //     this.getHistory()
  //   }, 60000)
  // },
  // destroyed() {
  //   clearInterval(this.interval)
  // },
  mounted() {
    // this.totalRows = this.items.length
    this.getHistory()
    this.getListAcc()
    this.getBankList()
  },
  methods: {
    async getHistory() {
      this.show = true
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_val: this.search_val,
        search_type: this.search_type,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .get('/movemoney', { params })
        .then(response => {
          this.show = false
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    async getListAcc() {
      this.$http
        .get('/movemoney/listAcc')
        .then(response => {
          if (response.data.status) {
            this.ListAcc = response.data.listAcc
          }
        })
        .catch(error => console.log(error))
    },
    async getBankList() {
      this.$http
        .get('/bank/list')
        .then(response => {
          this.BankList = response.data
        })
        .catch(error => console.log(error))
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getUsersSearch() {
      this.show2 = true
      const obj = {
        tel: this.tel,
      }
      this.$http
        .post('/users/search', obj)
        .then(response => {
          this.member = response.data
          this.search = true
          this.show2 = false
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
    },
    async ConfirmSubmit() {
      const AccData = this.ListAcc.find(x => x.ID === this.acc_data)
      if (AccData.bankcode === '004') {
        this.CheckAccNameKbank(this.bank, this.acc_no, Number(this.amount), AccData.prefix, AccData.type)
      } else {
        this.CheckAccNameScb(AccData.accno, this.acc_no, this.bank, Number(this.amount), AccData.prefix, AccData.ID)
      }
    },
    async CheckAccNameScb(Frombankcode, accno, Tobankcode, amount, prefix, banktype) {
      this.show = true
      const obj = {
        facc: Frombankcode,
        tacc: accno,
        tcode: Tobankcode,
        amount,
        prefix,
        banktype,
      }
      this.$http
        .post('movemoney/CheckAccNameScb', obj)
        .then(response => {
          this.show = false
          this.$swal({
            title: 'แจ้งเตือน ?',
            // eslint-disable-next-line no-undef
            text: `ยืนยันที่จะโยกเงินไปยังบัญชี ${
              response.data.status ? response.data.data.accountTo : 'ไม่สามารถเช็คชื่อได้'
            } ${
              response.data.status ? response.data.data.accountToName : 'ในขณะนี้'
            } หรือไม่ ?`, // สร้างข้อความใน Dialog
            icon: 'warning', // ใช้ไอคอนประเภท Warning
            showCancelButton: true,
            confirmButtonText: 'Yes',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.submit()
              this.show = false
            } else {
              this.submit()
              this.show = false
              this.$swal('ยกเลิก', 'ยกเลิกการ', 'error')
            }
          })
        })
        .catch(error => {
          this.show = false
          console.log(error)
          if (error.response) {
            this.$swal('เกิดข้อผิดพลาด', error.response.data.msg, 'error')
          }
        })
    },
    async CheckAccNameKbank(bankcode, accno, amount, prefix, type) {
      this.show = true
      const obj = {
        toBankCode: bankcode,
        toAccount: accno,
        amount,
        prefix,
        type,
      }
      this.$http
        .post('movemoney/CheckAccNameKbank', obj)
        .then(response => {
          this.show = false
          this.$swal({
            title: 'แจ้งเตือน ?',
            // eslint-disable-next-line no-undef
            text: `ยืนยันที่จะโยกเงินไปยังบัญชี \n ${response.data.data.bankName} ${response.data.data.toAccountNo} ${response.data.data.toAccountName} \n หรือไม่ ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.submit()
              this.show = false
            } else {
              this.show = false
              this.$swal('ยกเลิก', 'ยกเลิกการ', 'error')
            }
          })
        })
        .catch(error => {
          this.show = false
          console.log(error)
          if (error.response) {
            this.$swal('เกิดข้อผิดพลาด', error.response.data.msg, 'error')
          }
        })
    },
    async submit() {
      if (!this.acc_data || !this.acc_no || !this.bank || !this.amount) {
        this.SwalError('กรุณากรอกข้อมูลให้ครบถ้วน')
        return
      }
      const AccData = this.ListAcc.find(x => x.ID === this.acc_data)
      if (!AccData) {
        this.SwalError('ไม่พบบัญชีนี้ในระบบ')
      }
      this.show = true
      const formData = {
        frome: AccData.bankcode,
        frome_name: AccData.name,
        frome_acc: AccData.accno,
        amount: Number(this.amount),
        acc_no: this.acc_no,
        bank: this.bank,
        type: AccData.type,
      }
      this.$http
        .post('/movemoney/store', formData)
        .then(response => {
          this.show = false
          const res = response.data
          if (res.status) {
            this.getHistory()
            this.acc_data = null
            this.acc_no = null
            this.bank = null
            this.amount = null
            this.Success(res.msg)
          } else {
            this.SwalError(res.msg)
          }
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
          this.show = false
        })
    },
    addFavorite() {
      console.log('addFavorite')
    },
    Confirm(data, type) {
      if (type === 'approve') {
        this.showtext = 'อนุมัติ'
      } else if (type === 'cancel') {
        this.showtext = 'ยกเลิก'
      }
      this.$swal({
        title: 'แจ้งเตือน ?',
        // eslint-disable-next-line no-undef
        text: `ยืนยันที่จะ ${this.showtext} หรือไม่ ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          if (type === 'approve') {
            this.approve(data)
          } else if (type === 'cancel') {
            this.cancel(data)
          }
        } else {
          this.show = false
          this.$swal('ยกเลิก', `ยกเลิกการ${this.showtext}`, 'error')
        }
      })
    },
    approve(val) {
      this.show = true
      const params = {
        // eslint-disable-next-line no-underscore-dangle
        ID: val._id,
      }
      this.$http
        .get('/movemoney/approve', { params })
        .then(res => {
          this.show = false
          this.getHistory()
          const { msg } = res.data
          if (res.data.status) {
            this.Success(msg)
          } else {
            this.SwalError(msg)
          }
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    cancel(val) {
      this.show = true
      const params = {
        // eslint-disable-next-line no-underscore-dangle
        ID: val._id,
      }
      this.$http
        .get('/movemoney/cancel', { params })
        .then(res => {
          this.show = false
          this.getHistory()
          const { msg } = res.data
          if (res.data.status) {
            this.Success(msg)
          } else {
            this.SwalError(msg)
          }
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style scoped>
.favrt {
  margin: 0 10px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid #efceff8c;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.favrt-add {
  margin: 0 10px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #f8f0fc;
  border: 3px dotted #e4a2ec8c;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
.favrt-add i {
  color: #9191918e;
}

.txtn {
  position: relative;
}
.txtn .closed-x {
  position: absolute;
  top: -5px;
  right: 5px;
  background: #fc0202;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
  .bank-icon {
    width: 35px;
    height: 35px;
    padding: 5px;
    border-radius: 50%;
  }
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-item-section {
background-color:$product-details-bg;
}

</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
